import CustomLayout from "./wrapPageElement"
import "./src/bootstrap.css"
import "./src/global.css"
import "./src/adOverlay.css"
import "./src/transitions.css"
import "./src/font-awesome.min.css"
import { removeTrailingSlash } from "./src/utils/gatsby-node-helpers"
import { navigate } from '@reach/router';
import { unlocalizedPages, getFirstPathElement } from "./src/utils/unlocalizedPageCheck"
import BenchClient from "./src/utils/benchClient"
import randomNumber from "random-number-csprng";
import { getCountry } from "./src/utils/country";

export const wrapPageElement = CustomLayout

const languages = ['de', 'es', 'fr', 'hi', 'it', 'ja', 'ko', 'nl', 'pl', 'pt', 'pt-br', 'ru', 'tl', 'tr', 'zh-cn', 'zh-tw']
const regexUrlLocale = /^\/(?:intl\/([^\/]*))/

export const onRouteUpdate = ({ location, prevLocation }) => {
  let { unlocalizedPath } = getLocaleAndUnlocalizedPath(location.pathname)

  if (unlocalizedPath === "/") {
    BenchClient.ping('load.home')
  }
}

export const onPreRouteUpdate = (obj) => {

  // -store locale in session storage
  // when accessing unlocalized pages
  // 
  // -reapply locale
  // when leaving an unlocalized page

  let next = obj.location
  let prev = obj.prevLocation
  if(prev === null){ return }

  let nextFirstElement = getFirstPathElement(next.pathname)
  let prevFirstElement = getFirstPathElement(prev.pathname)

  if(nextFirstElement === prevFirstElement ){ return }

  if(unlocalizedPages.includes(nextFirstElement)){
    let { locale } = getLocaleAndUnlocalizedPath(prev.pathname)
    if( locale === "en" ){ return }
    sessionStorage.setItem('locale', locale)
  }
  else if(unlocalizedPages.includes(prevFirstElement)){

    let { unlocalizedPath } = getLocaleAndUnlocalizedPath(next.pathname)
    let locale = sessionStorage.getItem("locale")
    if(locale === null){
      return
    }
    let newPath = createLocalePath(locale, unlocalizedPath)
    sessionStorage.removeItem('locale')
    navigate(newPath)
  }
}

export const onClientEntry = async () => {
  // determine the country code as soon as possible into the session
  getCountry()
    .then(country => BenchClient.stick({ geo: {country} }));

  let ua = window.navigator.userAgent.toLowerCase()

  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(ua);

  let isAndroid = /(android|kindle|silk)/g.test(ua);
  let isMac = /macintosh/.test(ua);
  let isCatalina = (/mac os x 10_15/.test(ua) || /mac os x 10.15/.test(ua));
  let isLinux = !isAndroid && /linux/.test(ua);
  let isCrOS = /cros/.test(ua)
  let isIpad = /ipad/i.test(ua) || /macintosh/i.test(ua) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
  let isiOS = /iphone/i.test(ua)
  let isWindows = /(Windows NT)/i.test(ua)

  // Internet Explorer 6-11
  let isIE = /*@cc_on!@*/false || !!document.documentMode;

  // Edge 20+
  let isEdge = ua.indexOf("edg") > -1

  let isChrome = !!window.chrome && !isEdge;

  window.gatsbyIsMobile = isMobile 
  window.gatsbyIsAndroid = isAndroid 
  window.gatsbyIsChrome = isChrome
  window.gatsbyIsIE = isIE
  window.gatsbyIsEdge = isEdge
  window.gatsbyIsMac = isMac
  window.gatsbyIsCatalina = isCatalina
  window.gatsbyIsLinux = isLinux
  window.gatsbyIsCrOS = isCrOS
  window.gatsbyIsIpad = isIpad
  window.gatsbyIsiOS = isiOS
  window.gatsbyIsWindows = isWindows

  // determine the AB testing scenario
  const campaignId = '38F6E32A-0688-4132-B5DE-1BFAAB17C79D';
  const AB_KEY = 'x-ab';
  // 100% go to partition A for now
  const diviser = 0.5;
  const range = 1000;

  let userPartition = localStorage.getItem(campaignId);
  if (userPartition === null) {
    const isAPartition = await randomNumber(1, range)
      .then(n => n <= range * diviser);
    if (isAPartition || isMac) {
      userPartition = `${campaignId}-A`;
    } else {
      userPartition = `${campaignId}-B`;
    }
    localStorage.setItem(campaignId, userPartition);
  }
  window.utPartition = userPartition;
  // if the user partition is invalid, fallback to A
  const isValidPartition = (p) => {
    return [`${campaignId}-A`, `${campaignId}-B`].includes(p);
  }
  window.utIsAPartition = !isValidPartition(userPartition) || userPartition === `${campaignId}-A`;
  window.appendPartitionURLParameter = (input) => {
    let newUrl = new URL(input);
    newUrl.searchParams.set(AB_KEY, userPartition);
    return newUrl.toString();
  };
}

function getLocaleAndUnlocalizedPath(pathname){
  let locale = "en"
  let unlocalizedPath = ""
  let pathnameLocale = (pathname.match(regexUrlLocale)) ? pathname.match(regexUrlLocale)[1] : 'en'
  let localeInUrl = (pathname === "/") ? "en" : pathnameLocale

  if(languages.includes(localeInUrl)){
    locale = localeInUrl
    unlocalizedPath = pathname.replace(`/intl/${locale}`, "")
  }
  else{
    unlocalizedPath = pathname
  }
  return {
    unlocalizedPath:unlocalizedPath,
    locale:locale
  }
}

function createLocalePath(locale, path){
  if(locale === "en"){
    return removeTrailingSlash(path)
  }
  else{
    let newPath = "/"+ locale + path
    return removeTrailingSlash(newPath)
  }
}




