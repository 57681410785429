
export const WebProVpn = {
    webWin: 'https://store.bittorrent.com/u_w_pv_w',
    webMac: 'https://store.bittorrent.com/u_w_pv_m',
}
export const WebPro = {
    webWin: 'https://store.bittorrent.com/u_w_p_w',
    webMac: 'https://store.bittorrent.com/u_w_p_m',
}
export const WebAf = {
    webWin: 'https://store.bittorrent.com/u_w_af_w',
    webMac: 'https://store.bittorrent.com/u_w_af_m',
}

