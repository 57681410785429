import React from "react"
import useTranslations from "../../utils/useTranslations"
import adFreeButton from "../../images/prodnews/ut_adfree_button.png"
import googlePlayButton from "../../images/prodnews/google-play.png"
import nordTowerAd from "../../images/prodnews/nord_tower_300_600.png"
import ChimpForm from "../../components/chimpForm"
import { getNordVpnAdLink } from "../../utils/getNordVpnAdLink";

const minBuildVersion = 46206;
const nordVpnLink = getNordVpnAdLink();

function getUrlParameter(sParam) {
	if (typeof document === "undefined") return false;
	var sPageURL = window.location.search.substring(1),
		sURLVariables = sPageURL.split("&"),
		sParameterName,
		i;
	for (i = 0; i < sURLVariables.length; i++) {
		sParameterName = sURLVariables[i].split("=");

		if (sParameterName[0] === sParam) {
			return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
		}
	}
	return false;
}
class ProdNews extends React.Component{

	constructor(props){
		super(props)
		this.state = {
			isMac:false,
			isLegacyBuild: false,
		}
	}
  
	componentDidMount(){
		this.checkLegacyClassicBuild();
		if(window.gatsbyIsMac){
			this.setState({
				isMac:true
			})
		}
	}

	checkLegacyClassicBuild() {
		if (typeof document === 'undefined') return false;
		const versionParam = getUrlParameter('pv');
		if(!versionParam) return;
		const lastIndex = versionParam.lastIndexOf('.'); 
		const buildVersion = lastIndex !== -1 ? versionParam.substr(lastIndex + 1, versionParam.length) : "";
		if(typeof buildVersion === 'string' && buildVersion.trim().length > 0 && !isNaN(minBuildVersion)) {
			if(parseInt(buildVersion) <= minBuildVersion) {
				this.setState({
					isLegacyBuild: true,
				})
			}
		}
	}

	render(){

		const { text } = this.props.pageContext 

		const t = useTranslations(text)

		var winDisplay = style.show
		var macDisplay = style.hide
		if(this.state.isMac){
			winDisplay = style.hide
			macDisplay = style.show
		}

		let locale = this.props.pageContext.locale
		let catLink = (locale) ? `url(/images/pro-free-trial/cat-14days-${locale}.png)` : `url(/images/pro-free-trial/cat-14days-en.png)`

		let adBg = {
			background: catLink,
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
		}
		
		return (
			<div className="prodnews-container w-100 d-flex background-noise-dark">
				<div className="container align-self-center prodnews-inner-container my-5">
					
					<div className="row text-center text-white">
						<div className="col-9">
							<div className="row">
								{!this.state.isMac && this.state.isLegacyBuild ?
									<div className="col-12">
										<p style={font.lg} className="font-weight-bold mb-3">{t(`You are using an older version of µTorrent.`)}</p>
										<p className="mb-1">{t(`The new version has stability improvements and will launch faster.`)}</p>
										<p className="mb-0">
											{t("Please install the latest version from this link.", {"this link":
												<a id="prodnews-update-classic-legacy-build" className="font-weight-bold" href="https://www.utorrent.com/downloads/complete/track/stable/os/win/" target="_blank" rel="noopener noreferrer">
													{t(`this link`)}
												</a>
											})}
										</p>
									</div>
									:
									<div className="col-12">
										<p style={font.lg} className="font-weight-bold mb-3">{t(`You've just been upgraded to the latest version of µTorrent!`)}</p>
										<p className="mb-1">{t(`Every few weeks, we release a new version with improvements`)}</p>
										<p className="mb-0">
											{t("Read the blog post for what's new in this version.", {"new in this version":<>
												<a style={winDisplay} className="font-weight-bold" href="http://blog.utorrent.com/releases/windows/" target="_blank" rel="noopener noreferrer" id="prodnews-releasenotes-win">
													{t(`new in this version`)}
												</a>
												<a style={macDisplay} className="font-weight-bold" href="http://blog.utorrent.com/releases/mac-web/" target="_blank" rel="noopener noreferrer" id="prodnews-releasenotes-mac">
													{t(`new in this version`)}
												</a>
											</>})}
										</p>
									</div>
								}   
							</div>
							<div className="row my-5 justify-content-center">
								{this.state.isMac &&
									<div className="col-10 bg-dark p-5 rounded">
										<p className="text-white" style={font.md}>{t(`Get µTorrent news update and special offers!`)}</p>

											<ChimpForm
											  id="prodnews-mailchimp"
											  list="https://utorrent.us5.list-manage.com/subscribe/post?u=a3baea4e54ff8e8b235488c11&amp;id=c6e935196d"
											  classButton="prodnews-button"
											/>

										<p className="text-white" >
											{t(`By signing up, you agree to our Privacy Policy.`, {
											"Privacy Policy": <a href="https://www.bittorrent.com/legal/privacy-policy/" target="_blank" rel="noopenner noreferrer" id="prodnews-privacylink" className="font-weight-bold">{t(`Privacy Policy`)}</a>
											})}
										</p>
									</div>
								}
								{!this.state.isMac &&
									<a id="prodnews-pro-free-trial" target="_blank" href="https://store.bittorrent.com/849/purl-utprotrialweb14" style={{...adStyle.a, ...adBg}}>
						      		</a>
								}
							</div>
							<div className="row">
								<div className="col-8 offset-2">
									<div className="row">
										<div className="col-12 col-md-6 d-inline-block">
											<a id="prodnews-go-adfree-img" href="https://store.utorrent.com/u_c_af_w?x-source=uts" target="_blank" rel="noopener noreferrer">
												<img src={adFreeButton} alt="ad free button"/>
											</a>
											<p className="mt-3 mb-0">{t(`Try µTorrent without ads!`)}</p>
											<a id="prodnews-go-adfree-textlink" className="font-weight-bold" href="https://store.utorrent.com/u_c_af_w?x-source=uts" target="_blank" rel="noopener noreferrer">{t(`Go ad-free`)}</a>
										</div>
										<div className="col-12 col-md-6 d-inline-block">
											<a id="get-android-img" href="https://play.google.com/store/apps/details?id=com.utorrent.client&referrer=utm_source%3Dclient%26utm_medium%3Dweb%26utm_term%3Dprodnews%26utm_campaign%3Dprodnews" target="_blank" rel="noopener noreferrer">
												<img src={googlePlayButton} alt="Google Play button for µTorrent Android"/>
											</a>
											<p className="mt-3 mb-0">{t(`Get the Android mobile app.`)}</p>
											<a id="get-android-textlink" className="font-weight-bold" href="https://play.google.com/store/apps/details?id=com.utorrent.client&referrer=utm_source=client&utm_medium=web&utm_term=prodnews&utm_campaign=prodnews" target="_blank" rel="noopener noreferrer">On Google Play</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-3 d-flex align-items-center">
							<a id="prodnews-nord-tower-ad" href={nordVpnLink} target="_blank">
								<img className="img-fluid" src={nordTowerAd} alt="NordVPN - Get 66% off the 2-year plan"/>
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ProdNews

const adStyle = {
	a: {
		display: 'block',
		height: '349px',
		width: '420px'
	}
}
const font = {
	lg:{
		fontSize:'26px'
	},
	md:{
		fontSize:'20px'
	}
}

const style = {
	show:{
		display:'inline-block'
	},
	hide:{
		display:'none'
	}
}