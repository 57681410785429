import React, {useEffect, useState, forwardRef} from 'react';
import LocalizedLink from "../components/localizedLink"
import promoBannerImg from "../images/promotions/Summer2022/summer-sale-banner.png"
import promoPopupImg from "../images/promotions/Summer2022/summer-sale-overlay.png"
import copyIcon from "../images/promotions/2021BlackFriday/copy.svg"

const PROMO_CODE = "776a08a1-51b3-422a-adcc-2for1"
const PROMO_PREFIX = "bf-2024"

function appendCouponCode(baseLink, couponCode) {
  if(typeof baseLink === 'undefined') return null

  let queryKey = getQueryKey(baseLink)
  if(queryKey === null) return baseLink

  let querySymbol = baseLink.includes('?') ? '&' : '?'

  return baseLink+querySymbol+queryKey+couponCode
}

function copyText() {
    if (typeof document === 'undefined') return
    //getting text from P tag
    var copyText = document.getElementById("copyable-promo-code");  
    // creating textarea of html
    var input = document.createElement("textarea");
    //adding p tag text to textarea 
    input.value = copyText.textContent;
    document.body.appendChild(input);
    input.select();
    document.execCommand("Copy");
    // removing textarea after copy
    input.remove();
}

function getQueryKey(baseLink) {
  if(baseLink.includes('gateway.')) {
    return 'cp='
  }
  else if(baseLink.includes('store.')) {
    return 'coupon-code-to-add='
  }
  else if(baseLink.includes('cryptopayments.')) {
    return 'apply_coupon='
  }
  else {
    return null
  }
}

function getCookie(name) {
    if (typeof document === 'undefined') return
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function setCookie(name,value,days) {
    if (typeof document === 'undefined') return
    var expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    else{
        let date = new Date('December 1, 2022 18:00:00');
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getUrlParameter(sParam) {
  if (typeof document === 'undefined') return false
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};

export const CopyablePromoCode = (props) => {

  return(
   <div className="position-absolute promo-copy-icon-container" style={{top: props.top, right: props.right}}>
     <div id="copyable-promo-code" className="position-absolute" style={{zIndex: '-100', fontSize: 0}}>{PROMO_CODE}</div>
     <div onClick={(e) => {e.preventDefault(); copyText();}} style={{marginLeft: props.offsetRight}}>
       <img src={copyIcon} className="promo-copy-icon pointer" style={{width: props.size, height: props.size}}/>
     </div>
   </div>  
  )
}

export function getPromoLinks(linksObj) {
  let newLinksObj = {}
  Object.entries(linksObj).forEach(entry => {
    const [key, value] = entry;
    newLinksObj[key] = appendCouponCode(value, PROMO_CODE)
  });


  return newLinksObj

}

export const PromoBanner = () => {
  return(
    <div className="promo-banner-container d-none d-lg-flex">
      <div className="promo-banner h-100">
        <LocalizedLink id={`${PROMO_PREFIX}-promo-banner`} className="d-flex justify-content-center w-100 h-100 position-relative" to="/#comparison-panels">
          <img src={promoBannerImg} className="h-100 w-auto"/>
          {/* <CopyablePromoCode size="15px" offsetRight="960px" top="5px"/> */}
        </LocalizedLink>
      </div>
    </div>
  )
}

export const PromoPopup = React.forwardRef(({closeEvent, show}, ref) => (
    <div ref={ref} id="promo-bg" className="position-fixed vw-100 vh-100 d-flex align-items-center justify-content-center" style={{top: 0, left: 0, zIndex: `${show ? 1000 : -1000}`, visibility: `${show ? 'visible' : 'hidden'}`, background: 'rgba(0, 0, 0, 0.2)'}} onClick={closeEvent}>
      <div className="text-center position-relative pointer" style={{}}>
        <img id="promo-img" className="d-block position-relative w-100" style={{maxWidth: '659px'}} src={promoPopupImg}/>
        <CopyablePromoCode size="20px" right="133px" top="63.5%"/>
        <div id="promo-close" className="position-absolute pointer d-block text-white" style={{padding: '0 5px', right: '20px', top: '12px', width: '10px', height: '25px'}}>X</div>
        <div className="position-absolute text-white w-100" style={{bottom: '14%'}}>
          <div id="promo-buy-now" className="mx-auto font-weight-bold pointer" style={{border: "solid 2px white", borderRadius: '20px'}}>Apply Now</div>
        </div>
      </div>
    </div>
))

export const PromoPopupHandler = (props) => {
  const [showPromoPopup, setShowPromoPopup] = useState(false);
  const [showPromoDiscount, setShowPromoDiscount] = useState(getShowPromoDiscount());
  const handlePromoPopupClose = (e) => {
    let targetId = e.target.id
    if(targetId.includes('promo-')) {
      setShowPromoPopup(false)
    }
  }

  const handlePromoPopupOpen = (popupRef, e) => {
    setShowPromoPopup(true)
    e.persist()
    e.preventDefault()
    // Promise to wait and listen for popup click
    return new Promise((res) => {
        let handleClick = function(e){
          let targetId = e.target.id
          if(targetId === "promo-close" || targetId === "promo-bg" || targetId === "promo-buy-now" || targetId === "promo-img") {
            this.removeEventListener('click', handleClick)
            res(targetId)
          }
        }
        popupRef.current.addEventListener('click', handleClick)
    })
    .then((targetId) => {
      // if click is to close popup, 
      // load mini-banner + discounts
      // trigger download click again for business as usual
      if(targetId === "promo-close" || targetId === "promo-bg") {
        setCookie(`${PROMO_PREFIX}-promo-show-banner`, true)
        setCookie(`${PROMO_PREFIX}-promo-show-discounts`, true)
        e.target.click()
      }
      // else if click is to buy now,
      // apply discount prices and links site-wide via cookie
      else if(targetId === "promo-buy-now" || targetId === "promo-img") {
        setCookie(`${PROMO_PREFIX}-promo-show-discounts`, true)
        setShowPromoDiscount(true)
      }
    })
  }

  return {
    handlePromoPopupClose,
    handlePromoPopupOpen,
    showPromoDiscount,
    showPromoPopup
  }
}

export const promoPrices = {
  percent: '30%'
}

export function getShowPromoBanner() {
  // return false to disable promotion
  return false
  // return getCookie(`${PROMO_PREFIX}-promo-show-banner`) === 'true'
}

export var getShowPromoDiscount = () => {
  // return false to disable promotion

  // return true;
  return {
    proVpn: false,
    pro: false,
    adFree: false,
  };

  // let campaignCode = getUrlParameter('utm_campaign')
  // return getCookie(`${PROMO_PREFIX}-promo-show-discounts`) === 'true' || campaignCode === PROMO_CODE
}
